<template>
  <div>
    <page-header
      class="mb-3"
      :title="$t('Terms of use')"
    />
    <b-row class="d-flex justify-content-center">
      <b-col
        xs="12"
        md="8"
      >
        <b-card>
          <b-card-text>
            <h4>1. {{ $t('Terms of use - 1') }}</h4>
            <p>{{ $t('Terms of use - 1 - p1') }}</p>

            <h4>2. {{ $t('Terms of use - 2') }}</h4>
            <p>{{ $t('Terms of use - 2 - p1') }}</p>
            <p>{{ $t('Terms of use - 2 - p2') }}</p>
            <p>{{ $t('Terms of use - 2 - p3') }}</p>
            <p>{{ $t('Terms of use - 2 - p4') }}</p>

            <h4>3. {{ $t('Terms of use - 3') }}</h4>
            <p>{{ $t('Terms of use - 3 - p1') }}</p>

            <h4>4. {{ $t('Terms of use - 4') }}</h4>
            <p>{{ $t('Terms of use - 4 - p1') }}</p>
            <p>{{ $t('Terms of use - 4 - p2') }}</p>
            <p>{{ $t('Terms of use - 4 - p3') }}</p>

            <h4>5. {{ $t('Terms of use - 5') }}</h4>
            <p>{{ $t('Terms of use - 5 - p1') }}</p>
            <p>{{ $t('Terms of use - 5 - p2') }}</p>
            <p>{{ $t('Terms of use - 5 - p3') }}</p>
            <p>{{ $t('Terms of use - 5 - p4') }}</p>
            <p>{{ $t('Terms of use - 5 - p5') }}</p>
            <p>{{ $t('Terms of use - 5 - p6') }}</p>

            <h4>6. {{ $t('Terms of use - 6') }}</h4>
            <p>{{ $t('Terms of use - 6 - p1') }}</p>
            <p>{{ $t('Terms of use - 6 - p2') }}</p>
            <p>{{ $t('Terms of use - 6 - p3') }}</p>

            <h4>7. {{ $t('Terms of use - 7') }}</h4>
            <p>{{ $t('Terms of use - 7 - p1') }}</p>
            <p>{{ $t('Terms of use - 7 - p2') }}</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    PageHeader,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
p {
  line-height: 1.8rem;
}
</style>
